<template>

<div class="checklist" :class="{'is-error': error}">

	<app-icon s="error" v-if="error" class="error" />
	
	<div class="checklist-text">{{ input.label }}</div>

	<div class="checklist-items" :class="{'is-columns': input.columns}">

		<div class="checklist-item" v-for="(option, index) in input.options" :key="index">
	
			<div class="checklist-item-text">{{ option.name }}</div>

			<app-icon s="check" :class="{'is-active': $_.contains(value, option.id)}" class="checklist-item-icon" v-on:click.native="onToggleClick(option.id)" />

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['input', 'value', 'error'],

	methods: {

		onToggleClick: function(id) {

			var value = this.value

			if (this.$_.contains(value, id)) {

				value.splice(value.indexOf(id), 1)

			} else {

				if (this.input.limit === 0 || value.length < this.input.limit) value.push(id)

			}

			this.$emit('input', value)

		}

	}

}

</script>

<style scoped>

.checklist {
	padding-top: 4px;
	margin-bottom: 20px;
}

.checklist-text {
	padding: 8px 20px;
	min-height: 36px;
	line-height: 20px;
	color: #1A234C;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1.4px;
	width: 100%;
}

.checklist-items {
	border-radius: 23px;
	border: 1px solid #f5f5f5;
}

.checklist-items.is-columns {
	display: flex;
	flex-wrap: wrap;
	border: 0px;
}

.checklist-item {
	display: flex;
	width: 100%;
	border-bottom: 1px solid #f5f5f5;
}

.checklist-items.is-columns .checklist-item {
	border-bottom: 0px;
	border: 1px solid #f5f5f5;
	border-radius: 23px;
	margin-bottom: 10px;
}

.is-desktop .checklist-items.is-columns .checklist-item {
	width: calc(33.3% - 10px);
	margin-right: 10px;
}

.is-tablet .checklist-items.is-columns .checklist-item {
	width: calc(50% - 10px);
	margin-right: 10px;
}

.checklist-item:last-child {
	border-bottom: 0px;
}

.checklist-item-text {
	padding: 8px 20px;
	min-height: 36px;
	line-height: 20px;
	color: #1A234C;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1.4px;
	width: 100%;
	flex-grow: 1;
}

.checklist-item-icon {
	color: #eee;
	font-size: 14px;
	margin-right: 15px;
	flex-shrink: 0;
	width: 28px;
	height: 28px;
	margin-top: 4px;
	text-align: center;
	line-height: 26px;
	border-radius: 8px;
	cursor: pointer;
	border: 1px solid #ccc;
}

.checklist-item-icon:hover {
	border-color: #666;
	color: #666;
}

.checklist-item-icon.is-active {
	color: #fff;
	background-color: #1277D4;
	border-color: #1277D4;
}

</style>
